<template>
  <UiPopup
    :model-value="modelValue"
    title="Status update"
    description="You were offline. Choose your status to get back to the system."
    primary-button-text="Update the status"
    size="extraSmall"
    :loading="loading"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="setStatus"
  >
    <form ref="form" @submit.prevent>
      <UiInputCustomMenu
        id="showStatuses"
        ref="showStatuses"
        name="Statuses"
        class="mb-4"
        :error="useGetFieldErrors(v$, ['status'])"
        :width="312"
      >
        <template #activator="{ onClick, isOpen }">
          <div
            class="flex cursor-pointer flex-row items-center justify-between rounded-xl border border-black-20 px-4 py-3 text-black-100"
            @click="onClick"
          >
            <small>{{ statuses.find((el) => el.code === status)?.name || 'Choose an option' }}</small>
            <UiIcon name="chevron-big-filled-down" :class="[{ 'rotate-180': isOpen }, 'transition-all duration-200']" />
          </div>
        </template>
        <template #content>
          <div class="flex flex-col gap-3 py-2 pl-4 pr-7">
            <div
              v-for="item in statuses"
              :key="item.id"
              class="flex cursor-pointer flex-row items-center gap-4"
              :class="{ hidden: item.code === STATUSES.OFFLINE }"
              @click="selectStatus(item)"
            >
              <UiIcon :name="`${item.code}`" class="!h-3 !w-3" />
              <div class="flex flex-col">
                <span class="text-subhead-4">
                  {{ item.name }}
                </span>
                <span class="text-caption-2 text-black-70">
                  {{ item.description }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </UiInputCustomMenu>
      <div class="mb-6 mt-8 flex justify-center">
        <div class="relative">
          <UiAvatar
            :user="{ ...authStore.getUser, image: authStore.getUserImage }"
            classes="w-44 h-44 text-subhead-1 text-3xl !text-[70px]"
          />
          <div class="absolute bottom-0 right-0 rounded-full bg-white">
            <UiIcon :key="status" :name="status ? status : 'offline-dark'" class="!h-14 !w-14" />
          </div>
        </div>
      </div>
    </form>
  </UiPopup>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { STATUSES } from '~/constants'
import { useAuthStore } from '@/store/auth'
import { useUiStore } from '~/store/ui'
import type { Status } from '@/types'

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const uiStore = useUiStore()
const authStore = useAuthStore()

const status = ref()
const statuses = ref<Status[]>([])
const showStatuses = ref<HTMLElement>()

const rules = computed(() => ({
  status: { required: helpers.withMessage('The status is required', required) },
}))

const v$ = useVuelidate(rules, { status })

onNuxtReady(() => getStatuses())

const getStatuses = async () => {
  const response = await useUserStatuses()
  response as Status[]
  statuses.value = response.filter(
    (status: Status) => status.code !== STATUSES.INACTIVE && status.code !== STATUSES.OFFLINE
  )
}

const loading = ref(false)

const getStatusInfo = () => statuses.value.find((s: Status) => s.code === status.value)

const selectStatus = (s: Status) => {
  status.value = s.code
  showStatuses.value?.closeMenu()
}

const setStatus = async () => {
  if (!(await v$.value.$validate())) return
  loading.value = true
  if (!status.value) status.value = STATUSES.OFFLINE
  const statusId = getStatusInfo()?.id

  try {
    authStore.setUserStatus(statusId!)
    await useSetUserStatus(Number(authStore.getUser.id), statusId!)
    uiStore.showSnackBanner()
    emits('input', false)
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
